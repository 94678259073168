// Here you can add other styles

.avatar {
  img.avatar-img {
    overflow: hidden;
    height: 100%;
    object-fit: cover;
  }
}

.error {
  color: red;
  font-size: 12px;
  font-style: italic;
  margin-top: 3px;
}

.overflow-wrap {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
